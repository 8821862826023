import './Styles/Team.css';
import './Styles/Animation.css';
import linkedin from "./Resources/linkedin2.svg"
import moizPic from "./Resources/Moiz.png";
// import namanPic from "./Resources/Naman.png";
import namanPic from "./Resources/Naman2.png";
import niravPic from "./Resources/niravPic.png"
import shalinPic from "./Resources/shalinPic.png"
import { useEffect } from 'react';

function Animate(){
  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        // It's visible. Add the animation class here!
        if(entry.target.classList.contains("watch2")){
          entry.target.classList.add("inView2");
        }
        else if(entry.target.classList.contains("watch")){
          entry.target.classList.add("inView");
        }
        
      }
      else{
        entry.target.classList.remove("inView");
        entry.target.classList.remove("inView2");
      }
    });
  });
  
  // Tell the observer which elements to track
  let elementsToWatch=document.querySelectorAll('.watch');
  elementsToWatch.forEach(element=>{
    observer.observe(element);
  })
  let elementsToWatch2=document.querySelectorAll('.watch2');
  elementsToWatch2.forEach(element=>{
    observer.observe(element);
  })

}


function Team() {
  useEffect(()=>{
    Animate();
  },[])
  return (
    <div className="Team">
      <h1 className='Team-head watch'>Guided by strong leadership</h1>
      <div className='Team-members'>
        <div className='Team-members-member'>
        <div className='Team-members-member-card watch'>
          <img className='Team-members-member-card-img' src={moizPic} alt="Moiz Daruwala"/>
          
          <h2 className='Team-members-member-card-name'>Moiz Ezzi</h2>
          <a href="https://www.linkedin.com/in/moiz-ezzi" rel="noreferrer" target="_blank"><img className='linkedin' src={linkedin} alt="linkedin" /></a>
        </div>
        <div className='Team-members-member-content watch'>
          
          <h2 className='Team-members-member-content-designation'>Co-Founder</h2>
          <h2 className='Team-members-member-content-education'>CPA (USA, Ireland), CA, Register Valuer</h2>
          <p className='Team-members-member-content-desc'>Moiz founded CrossRight and onboards new clients with his exceptional skills in taxation, audit, and business valuation.</p>
         </div>
          
        </div>
        <div className='Team-members-member'>
        <div className='Team-members-member-card watch'>
        <img className='Team-members-member-card-img' src={namanPic} alt="Naman Kothari"/>
          
          <h2 className='Team-members-member-card-name'>Naman Kothari</h2>
          <a href="https://www.linkedin.com/in/ca-naman-kothari" rel="noreferrer" target="_blank"><img className='linkedin' src={linkedin} alt="linkedin" /></a>
        </div>
        <div className='Team-members-member-content watch'>
        
          <h2 className='Team-members-member-content-designation'>Founder</h2>
          <h2 className='Team-members-member-content-education'>Chartered Accountant</h2>
          <p className='Team-members-member-content-desc'>Naman has a sharp eye for detail and specializes in property management accounting and forensic audits.</p>
          
        </div>
         
        </div>
        <div className='Team-members-member'>
        <div className='Team-members-member-card watch'>
        <img className='Team-members-member-card-img' src={niravPic} alt="Nirav Soni"/>
          
        <h2 className='Team-members-member-card-name'>Nirav Soni</h2>
        <a href="https://www.linkedin.com/in/ca-nirav-soni" rel="noreferrer" target="_blank"><img className='linkedin' src={linkedin} alt="linkedin" /></a>
        </div>
          
          
        <div className='Team-members-member-content watch'>
          
          
          <h2 className='Team-members-member-content-designation'>Co-Founder</h2>
          <h2 className='Team-members-member-content-education'>CA, CPA Australia</h2>
          <p className='Team-members-member-content-desc'>Nirav ensures smooth sailing of the firm and efficiently heads financial planning, analysis, and reporting.</p>
        </div>
        </div>
      </div>
      <div><br></br></div>
      <div className='Team-members'>
        <div className='Team-members-member'>
        </div>
        
        <div className='Team-members-member' >
        <div className='Team-members-member-card watch'>
        <img className='Team-members-member-card-img' src={shalinPic} alt="Shalin Acharya"/>
          
        <h2 className='Team-members-member-card-name'>Shalin Acharya</h2>
        {/* <a href="https://www.linkedin.com/in/ca-nirav-soni" rel="noreferrer" target="_blank"><img className='linkedin' src={linkedin} alt="linkedin" /></a> */}
        </div>
          
          
        <div className='Team-members-member-content watch'>
          
          
          <h2 className='Team-members-member-content-designation'>Head of Business Operations - US</h2>
          <h2 className='Team-members-member-content-education'></h2>
          <p className='Team-members-member-content-desc'>Expert in business operations and development, with 10+ years of experience delivering quality solutions to clients and business owners.</p>
        </div>
        </div>
        
        <div className='Team-members-member'></div>
        
      </div>
      
    </div>
  );
}

export default Team;
